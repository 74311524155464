import React, { useContext, useEffect, useState } from 'react';
import { DarkModeContext } from '../../context/DarkModeContext';

const ContenidoCupones = () => {
    const { darkMode } = useContext(DarkModeContext);
    const [cargando, setCargando] = useState(false);
    const [datos, setDatos] = useState([]);
    const [paginaActual, setPaginaActual] = useState(1);
    const [terminoBusqueda, setTerminoBusqueda] = useState('');
    const [fechaFiltro, setFechaFiltro] = useState('');
    const [resultadosPorPagina, setResultadosPorPagina] = useState(20);

    useEffect(() => {
        const fetchDatos = async () => {
            setCargando(true);

            try {
                // Obtener el token del sessionStorage
                const token = sessionStorage.getItem('token');
                if (!token) {
                    console.error('No se encontr� el token en el sessionStorage');
                    return;
                }

                // Hacer la solicitud a la API
                const response = await fetch('/api/clover/obtenerdatos', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ Token: token }), // Enviar el token en el cuerpo
                });

                if (!response.ok) {
                    throw new Error(`Error en la solicitud: ${response.statusText}`);
                }

                const data = await response.json();
                setDatos(data);
            } catch (error) {
                console.error('Error al obtener los datos:', error);
            } finally {
                setCargando(false);
            }
        };

        fetchDatos();
    }, []);
    const filtrarDatos = () => {
        return datos.filter((dato) => {
            const monto = parseFloat(dato.importe).toFixed(2);
            const nroTarjeta = dato.numeroDeLaTarjeta;
    
            // Formatea la fecha de los datos en formato ISO (YYYY-MM-DD)
            const fechaPago = new Date(dato.fechaDePago).toISOString().split('T')[0];
    
            // Comprueba si el término de búsqueda coincide con monto o número de tarjeta
            const coincideBusqueda =
                monto.includes(terminoBusqueda) || nroTarjeta.includes(terminoBusqueda);
    
            // Comprueba si la fecha coincide exactamente con el filtro de fecha
            const coincideFecha = !fechaFiltro || fechaPago === fechaFiltro;
    
            // Retorna true si ambas condiciones son ciertas
            return coincideBusqueda && coincideFecha;
        });
    };

    const datosFiltrados = filtrarDatos();
    const totalPaginas = Math.ceil(datosFiltrados.length / resultadosPorPagina);

    const datosPaginados = datosFiltrados.slice(
        (paginaActual - 1) * resultadosPorPagina,
        paginaActual * resultadosPorPagina
    );

    const manejarCambioPagina = (direccion) => {
        if (direccion === 'anterior' && paginaActual > 1) {
            setPaginaActual(paginaActual - 1);
        } else if (direccion === 'siguiente' && paginaActual < totalPaginas) {
            setPaginaActual(paginaActual + 1);
        }
    };

    const limpiarFiltros = () => {
        setTerminoBusqueda('');
        setFechaFiltro('');
        setPaginaActual(1);
    };


    return (
        <div
            className={
                darkMode
                    ? 'container bg-tabla-usuarios-liquidaciones-dark'
                    : 'container bg-tabla-usuarios-liquidaciones'
            }
        >
             <div className="mb-3">
                <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="Buscar por monto o N° de tarjeta"
                    value={terminoBusqueda}
                    onChange={(e) => {
                        setTerminoBusqueda(e.target.value);
                        setPaginaActual(1); // Reiniciar a la primera página al buscar
                    }}
                />
                <input
                    type="date"
                    className="form-control mb-2"
                    value={fechaFiltro}
                    onChange={(e) => {
                        setFechaFiltro(e.target.value);
                        setPaginaActual(1); // Reiniciar a la primera página al buscar
                    }}
                />
                <div className="d-flex align-items-center">
                    <select
                        className="form-select me-2"
                        value={resultadosPorPagina}
                        onChange={(e) => setResultadosPorPagina(Number(e.target.value))}
                    >
                        {[10, 20, 50].map((cantidad) => (
                            <option key={cantidad} value={cantidad}>
                                {cantidad} por página
                            </option>
                        ))}
                    </select>
                    <button className="btn btn-secondary" onClick={limpiarFiltros}>
                        Limpiar Filtros
                    </button>
                </div>
            </div>
            <article>
                <div
                    className={
                        darkMode
                            ? 'table-responsive py-3 px-5'
                            : 'table-responsive py-3 px-5'
                    }
                >
                    <table className="table table-borderless responsive striped hover">
                        <thead className="border-0">
                            <tr className="text-center tabla-thead">
                                <th className={darkMode ? 'bg-white text-dark border-tabla-izquierda border-0 lato-regular fs-12 py-3' : 'bg-dark text-white border-tabla-izquierda border-0 lato-regular fs-12 py-3'}>
                                    Fecha de Pago
                                </th>
                                <th className={darkMode ? 'bg-white text-dark border-0 lato-regular fs-12 py-3' : 'bg-dark text-white border-0 lato-regular fs-12 py-3'}>
                                    ID Terminal
                                </th>
                                <th className={darkMode ? 'bg-white text-dark border-0 lato-regular fs-12 py-3' : 'bg-dark text-white border-0 lato-regular fs-12 py-3'}>
                                    Importe
                                </th>
                                <th className={darkMode ? 'bg-white text-dark border-0 lato-regular fs-12 py-3' : 'bg-dark text-white border-0 lato-regular fs-12 py-3'}>
                                    Marca de Tarjeta
                                </th>
                                <th className={darkMode ? 'bg-white text-dark border-0 lato-regular fs-12 py-3' : 'bg-dark text-white border-0 lato-regular fs-12 py-3'}>
                                    Medio de Pago
                                </th>
                                <th className={darkMode ? 'bg-white text-dark border-0 lato-regular fs-12 py-3' : 'bg-dark text-white border-0 lato-regular fs-12 py-3'}>
                                    Nombre del Cliente
                                </th>
                                <th className={darkMode ? 'bg-white text-dark border-0 lato-regular fs-12 py-3' : 'bg-dark text-white border-0 lato-regular fs-12 py-3'}>
                                    Lote
                                </th>
                                <th className={darkMode ? 'bg-white text-dark border-0 lato-regular fs-12 py-3' : 'bg-dark text-white border-0 lato-regular fs-12 py-3'}>
                                    Recibo
                                </th>
                                <th className={darkMode ? 'bg-white text-dark border-0 lato-regular fs-12 py-3' : 'bg-dark text-white border-0 lato-regular fs-12 py-3'}>
                                    N° de Cuotas
                                </th>
                                <th className={darkMode ? 'bg-white text-dark border-0 lato-regular fs-12 py-3' : 'bg-dark text-white border-0 lato-regular fs-12 py-3'}>
                                    N° de Tarjeta
                                </th>
                                <th className={darkMode ? 'bg-white text-dark border-tabla-derecha border-0 lato-regular fs-12 py-3' : 'bg-dark text-white border-tabla-derecha border-0 lato-regular fs-12 py-3'}>
                                    Resultado
                                </th>
                            </tr>
                        </thead>
                        <tbody className="text-center">
                        {cargando ? (
                                <tr>
                                    <td colSpan="11">Cargando...</td>
                                </tr>
                            ) : datosPaginados.length > 0 ? (
                                datosPaginados.map((dato, index) => (
                                    <tr
                                        key={index}
                                        className={darkMode ? 'tabla-borde-bottom text-white' : 'tabla-borde-bottom text-dark'}
                                    >
                                        <td className="fs-12-a-10 lato-regular pt-4">
                                            {new Date(dato.fechaDePago).toLocaleDateString()}
                                        </td>
                                        <td className="fs-12-a-10 lato-regular pt-4">
                                            {dato.idTerminal}
                                        </td>
                                        <td className="fs-12-a-10 lato-regular pt-4">
                                            ${parseFloat(dato.importe).toFixed(2)}
                                        </td>
                                        <td className="fs-12-a-10 lato-regular pt-4">
                                            {dato.marcaDeLaTarjeta}
                                        </td>
                                        <td className="fs-12-a-10 lato-regular pt-4">
                                            {dato.medioDePago}
                                        </td>
                                        <td className="fs-12-a-10 lato-regular pt-4">
                                            {dato.nombreDelCliente}
                                        </td>
                                        <td className="fs-12-a-10 lato-regular pt-4">
                                            {dato.nrLote}
                                        </td>
                                        <td className="fs-12-a-10 lato-regular pt-4">
                                            {dato.nrRecibo}
                                        </td>
                                        <td className="fs-12-a-10 lato-regular pt-4">
                                            {dato.numeroCuotas || 'N/A'}
                                        </td>
                                        <td className="fs-12-a-10 lato-regular pt-4">
                                            **** {dato.numeroDeLaTarjeta}
                                        </td>
                                        <td className="fs-12-a-10 lato-regular pt-4">
                                            {dato.rersultado}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="11">No se encontraron datos</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="d-flex justify-content-between mt-3">
                    <button
                        className="btn btn-secondary"
                        onClick={() => manejarCambioPagina('anterior')}
                        disabled={paginaActual === 1}
                    >
                        Anterior
                    </button>
                    <span>
                        Página {paginaActual} de {totalPaginas}
                    </span>
                    <button
                        className="btn btn-secondary"
                        onClick={() => manejarCambioPagina('siguiente')}
                        disabled={paginaActual === totalPaginas}
                    >
                        Siguiente
                    </button>
                </div>
            </article>
        </div>
    );
};

export default ContenidoCupones;
