import React, { useState } from "react";
import { Button, Row, Col, Form, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faUpload } from "@fortawesome/free-solid-svg-icons";

const ContenidoClover = () => {
  const [files, setFiles] = useState([]); // Cambiar a una lista para manejar múltiples archivos
  const [message, setMessage] = useState("");

  const handleFileChange = (e) => {
    setFiles([...e.target.files]); // Almacenar todos los archivos seleccionados
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = sessionStorage.getItem("token");

    if (!token) {
      setMessage("Token no disponible. Inicia sesión nuevamente.");
      return;
    }

    if (files.length === 0) {
      setMessage("Por favor selecciona al menos un archivo.");
      return;
    }

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file); // Enviar cada archivo con el mismo nombre de clave
    });
    formData.append("token", token); // Si necesitas enviar el token como parte del cuerpo

    try {
      const response = await fetch("/api/clover/subirdatosclover", {
        method: "POST",
        body: formData, // Enviar los archivos y token
      });

      if (response.ok) {
        const data = await response.json();
        setMessage(
          `Archivos subidos exitosamente. Total de archivos procesados: ${data.FilesCount}`
        );
      } else {
        const errorData = await response.json();
        setMessage(errorData.message || "Error al subir los archivos.");
      }
    } catch (error) {
      setMessage("Hubo un problema al conectar con el servidor.");
    }
  };
  return (
    <div className="container" style={{ marginBottom: "10px" }}>
      <h2 className="fs-24 lato-bold mb-0" style={{ color: "#292B2F" }}>
        Subir Datos Clover
      </h2>

      <form onSubmit={handleSubmit}>
        <Row className="my-3 align-items-center">
          <Col xs="auto">
            <label
              htmlFor="fileInput"
              style={{
                display: "inline-block",
                width: "200px",
                height: "44px",
                background: "#B4C400",
                color: "#FFFFFF",
                borderRadius: "32px",
                textAlign: "center",
                lineHeight: "44px",
                cursor: "pointer",
              }}
            >
              {" "}
              <FontAwesomeIcon icon={faFile} style={{ marginRight: "5px" }} />
              Seleccionar Archivos
            </label>
            <input
              id="fileInput"
              type="file"
              accept=".csv"
              multiple
              onChange={handleFileChange}
              style={{ display: "none" }} // Ocultar el input real
            />
          </Col>
          <Col xs="auto">
            <Button
              type="submit" // Esto es clave para activar el evento onSubmit del formulario
              className="btn-publicar border-0 mx-1"
              style={{
                width: "200px",
                height: "44px",
                background: "#B4C400",
                color: "#FFFFFF",
                borderRadius: "32px",
              }}
            >
              {" "}
              <FontAwesomeIcon icon={faUpload} style={{ marginRight: "5px" }} />
              Subir Archivos
            </Button>
          </Col>
        </Row>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default ContenidoClover;
